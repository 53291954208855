// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-simple-page-template-js": () => import("./../src/templates/simple-page-template.js" /* webpackChunkName: "component---src-templates-simple-page-template-js" */),
  "component---src-templates-collection-template-js": () => import("./../src/templates/collection-template.js" /* webpackChunkName: "component---src-templates-collection-template-js" */),
  "component---src-templates-news-page-template-js": () => import("./../src/templates/news-page-template.js" /* webpackChunkName: "component---src-templates-news-page-template-js" */),
  "component---src-templates-news-template-js": () => import("./../src/templates/news-template.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */)
}

